<template>
  <div class="box">
    <div style="display: flex; justify-content: space-between">
      <div>
        {{ $route.query.projectName }}扫码记录：
        <el-date-picker
          style="width: 150px"
          v-model="getForm.date"
          type="date"
          value-format="yyyy-MM-dd"
          size="small"
          @change="getUseRate()"
          :picker-options="pickerOption"
        />
      </div>
      <div
        style="
          cursor: pointer;
          color: rgb(2, 185, 128);
          font-size: 14px;
          line-height: 28px;
        "
        @click="$router.go(-1)"
      >
        <i class="el-icon-arrow-left"></i>返回
      </div>
    </div>

    <div style="width: 100%; height: 95%; margin-top: 20px">
      <el-table height="500" :data="tableData">
        <el-table-column prop="barcode" align="center" label="内容">
        </el-table-column>
        <el-table-column prop="duration" align="center" label="间隔时长">
          <template slot-scope="{ row }">
            <span> {{ row.duration | timeFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="datetime"
          label="上传时间"
          :formatter="formatter"
        >
        </el-table-column>
      </el-table>
      <el-row
        type="flex"
        justify="end"
        style="margin-top: 20px; padding-right: 20px"
      >
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="getForm.current"
          :page-sizes="[10, 20, 30, 40]"
          :total="getForm.total"
          :page-size="getForm.size"
          layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </el-row>
    </div>
  </div>
</template>

<script>
let that;
import * as echarts from "echarts";
export default {
  data() {
    return {
      nowShiftId: "",
      getForm: {
        projectId: this.$route.query.projectId,
        current: 1,
        size: 10,
        date: "",
        total: 0,
      },
      tableData: [],
      currentShift: "",
      options: [],
      radio: 1,
      dateValue: "",
      zoom: 1,
      pickerOption: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  created() {
    this.dateValue = this.dayjs().format("YYYY-MM-DD");
  },
  mounted() {
    that = this;
    that.getForm.date = this.dayjs().format("YYYY-MM-DD");
    that.getShift();
  },
  methods: {
    formatter(val) {
      let time = val.createTime ;
      return this.dayjs(time).format("HH:mm:ss");
    },
    handleSizeChange(val) {
      this.getForm.size = val;
      this.getUseRate();
    },
    handleCurrentChange(val) {
      this.getForm.current = val;
      this.getUseRate();
    },
    async getShift() {
      await this.$api.triColorLamp
        .getShift(this.$route.query.projectId)
        .then((res) => {
          if (res.code == 200) {
            this.options = res.data.ShiftList || [];
            this.currentShift = res.data.shiftId || "";
            if (this.currentShift) {
              this.getForm.type = 2;
            } else {
              this.getForm.type = 1;
            }
          }
        });
      this.getUseRate();
    },
    onetrendChange(val) {
      if (val == 1) {
        this.nowShiftId = this.currentShift;
        this.currentShift = "";
        this.getUseRate();
      } else {
        this.currentShift = this.nowShiftId;
        this.getUseRate();
      }
    },
    async getUseRate() {
      try {
        this.getForm.shiftId = this.currentShift;
        const res = await this.$api.scanner.getScannerHistory(this.getForm);
        if (res.code == 200) {
          this.tableData = res.data.records;
          this.getForm.total = res.data.total;
        }
      } catch (error) {}
    },
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  text-align: left;
  box-sizing: border-box;
}
.box {
  padding: 10px;
  width: 100%;
  height: 100%;
}
/* @import url(); 引入css类 */
</style>
